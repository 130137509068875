<template>
  <div class="content md flex-box vertical">
    <div class="area">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" layout="vertical" label-align="left" class="flex-grow">
        <a-form-model-item label="状态" prop="status">
          <a-select placeholder="选择状态" :options="fixedStatusOptions" v-model="form.status"></a-select>
        </a-form-model-item>
        <a-form-model-item label="操作日期" prop="operate_date">
          <a-date-picker
            v-model="form.operate_date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            placeholder="选择日期"
            style="width: 100%;"
            :disabled-date="disabledDate"
            :allow-clear="false"
          />
        </a-form-model-item>
        <a-form-model-item class="info-list" prop="list">
          <a-button type="primary" @click="addInfo">添加固定资产记录</a-button>
          <a-table :columns="columns" :data-source="form.list" class="info-table" :row-key="(record) => record.key">
            <div class="row-btn" slot="action" slot-scope="text, record, index">
              <a class="txt-btn" @click.stop="editRow(record, index)">编辑</a>
              <a class="txt-btn danger" @click.stop="delRow(record, index)">删除</a>
            </div>
          </a-table>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-space class="footer">
      <a-button type="primary" @click="handleSave">保存</a-button>
    </a-space>
    <BasicModal
      :title="`${edit ? '编辑' : '添加'}固定资产记录`"
      :initial="single"
      v-model="visible"
      :rules="editRules"
      :footer="edit ? 1 : 2"
      :success="addSuccess"
    >
      <template v-slot:edit="{ form }">
        <a-form-model-item label="商品名称" prop="goods_id">
          <GoodsSelect v-model="form.goods_id" :form="form" :options="goodsOptions" />
        </a-form-model-item>
        <a-form-model-item label="资产编码类别" prop="category">
          <a-select placeholder="选择资产编码类别" :options="fixedAssetsOptions" v-model="form.category" allowClear>
          </a-select>
        </a-form-model-item>
        <!-- <a-form-model-item label="部门" prop="dept_id">
          <select-file v-model="form.dept_id" @change="deptChange($event, form)"></select-file>
        </a-form-model-item> -->
        <a-form-model-item label="堂口名称" prop="ketang_id">
          <a-select
            placeholder="选择堂口"
            :options="tkOptions"
            v-model="form.ketang_id"
            allowClear
            show-search
            option-filter-prop="children"
            @change="tangkouChange($event, form)"
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="所在位置">
          <a-input v-model="form.position" placeholder="请输入所在位置" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item label="保管人" prop="custodian" :extra="userList.length > 0 ? '' : '该堂口暂未设置主管'">
          <a-select placeholder="选择保管人" v-model="form.custodian" allowClear>
            <a-select-option :value="user" v-for="user in userList" :key="user">
              <open-data type="userName" :openid="user" />
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="规格型号">
          <a-input v-model="form.spec" placeholder="请输入规格型号" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item label="物品来源">
          <a-select placeholder="选择资物品来源" :options="fixedSourceOptions" v-model="form.source" allowClear>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="附件">
          <form-upload
            v-model="form.annex"
            listType="text"
            :size="50 * 1024 * 1024 * 1"
            customAccept=".excel,.word,.pdf"
            uploadTip="仅支持excel，word，pdf后缀的文件"
            save-file-name
          ></form-upload>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea
            v-model="form.notes"
            placeholder="填写备注"
            :autoSize="{ minRows: 2, maxRows: 6 }"
            allowClear
          ></a-textarea>
        </a-form-model-item>
      </template>
    </BasicModal>
  </div>
</template>

<script>
import moment from "moment";
import { fixedStatusOptions, fixedSourceOptions, fixedAssetsOptions } from "../../common/asset/type";
import {
  clone,
  dataConvertOptions,
  downLoadFile,
  getKeyTitle,
  getSuffix,
  randomString,
  stringConvertTimestamp,
} from "../../common/js/tool";
import BasicModal from "./components/BasicModal.vue";
import GoodsSelect from "./components/GoodsSelect.vue";

export default {
  name: "FixedAdd",
  components: {
    BasicModal,
    GoodsSelect,
  },
  data() {
    return {
      form: {
        status: 1, // 默认库内
        operate_date: new Date().pattern("yyyy-MM-dd"), // 默认当前日期
      },
      rules: {
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
        operate_date: [{ required: true, message: "请选择操作日期", trigger: "change" }],
        list: [{ required: true, message: "请添加固定资产记录", trigger: "change" }],
      },
      fixedStatusOptions: clone(fixedStatusOptions).slice(0, -1),
      columns: [
        {
          title: "商品名称",
          customRender: (text) => {
            return text?.goods?.name || "";
          },
        },
        { title: "规格型号", dataIndex: "spec" },
        {
          title: "物品来源",
          dataIndex: "source",
          customRender: (text) => {
            return <span>{getKeyTitle(fixedSourceOptions, text, "value", "label")}</span>;
          },
        },
        { title: "当前位置", dataIndex: "position" },
        {
          title: "保管人",
          dataIndex: "custodian",
          customRender: (text) => {
            return <open-data type="userName" openid={text} />;
          },
        },
        {
          title: "附件",
          dataIndex: "annex",
          customRender: (text) => {
            return text ? <a onclick={() => downLoadFile(text)}>{"文件" + getSuffix(text)}</a> : "";
          },
        },
        { title: "操作", dataIndex: "action", fixed: "right", width: 100, scopedSlots: { customRender: "action" } },
      ],
      visible: false,
      single: null,
      editIndex: undefined,
      fixedAssetsOptions,
      fixedSourceOptions,
      tkOptions: [],
      userList: [],
      editRules: {
        goods_id: [{ required: true, message: "请输入商品名称", trigger: "change" }],
        category: [{ required: true, message: "请选择资产编码类别", trigger: "change" }],
        // dept_id: [{ required: true, message: "请选择部门", trigger: "change" }],
        ketang_id: [{ required: true, message: "请选择堂口", trigger: "change" }],
        custodian: [{ required: true, message: "请选择保管人", trigger: "change" }],
      },

      goodsOptions: [],
    };
  },
  computed: {
    edit() {
      return this.single;
    },
  },
  created() {
    this.getTk();
  },
  methods: {
    // 获取堂口
    getTk() {
      this.$axios({
        url: "/admin/ketang",
        method: "GET",
        params: {
          sort: "order,-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.tkOptions = dataConvertOptions(res.data);
      });
    },
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    // 堂口改变
    tangkouChange(val, form) {
      this.userList = [];
      this.$set(form, "custodian", undefined);
      if (val) {
        let target = this.tkOptions.find((item) => item.value === val);
        this.userList = target.admin_user_ids?.split(",") || [];
      }
    },
    getUser(val) {
      this.$axios({
        url: "/admin/ketang",
        method: "GET",
        params: {
          sort: "-id",
          pageSize: 9999,
          "filter[dept_id]": val[0],
        },
      }).then((res) => {
        let userList = [];
        res.data?.forEach((item) => {
          let ids = item.admin_user_ids;
          if (ids) {
            ids = ids.split(",");
            userList = userList.concat(ids);
          }
        });
        // 去重
        this.userList = Array.from(new Set(userList));
      });
    },
    deptChange(val, form) {
      this.userList = [];
      if (val && val.length > 0) {
        this.getUser(val);
      } else {
        form.custodian = undefined;
      }
    },
    // 清除编辑参数
    clearEdit() {
      this.single = null;
      this.editIndex = undefined;
    },
    // 添加固定资产记录
    addInfo() {
      this.clearEdit();
      this.visible = true;
    },
    // 成功添加固定资产记录
    addSuccess(form, close) {
      this.$set(this.form, "list", this.form.list || []);
      if (form.key) {
        // 编辑
        this.form.list[this.editIndex] = form;
      } else {
        // 新增
        this.form.list.push({ key: randomString(16), ...form });
      }
      close();
      this.userList = [];
    },
    // 编辑固定资产记录
    editRow(record, index) {
      if (record.ketang_id) {
        let target = this.tkOptions.find((item) => item.value === record.ketang_id);
        this.userList = target.admin_user_ids?.split(",") || [];
      }
      this.single = record;
      this.editIndex = index;
      this.visible = true;
    },
    // 删除固定资产记录
    delRow(record, index) {
      this.$confirm({
        title: "提示",
        content: `确定删除此固定资产记录吗？`,
        onOk: () => {
          this.form.list.splice(index, 1);
        },
      });
    },
    // 保存
    handleSave() {
      let ruleForm = this.$refs.ruleForm;
      ruleForm.validate((flag) => {
        if (flag) {
          this.$confirm({
            title: "提示",
            content: `确定保存此固定资产记录吗？`,
            onOk: () => {
              let form = clone(this.form);
              if (form.operate_date) {
                form.operate_date = stringConvertTimestamp(form.operate_date);
              }
              if (form.list?.length) {
                form.list.forEach(item => {
                  if (item.annex) {
                    item.annex = JSON.stringify(item.annex);
                  }
                })
              }
              this.$axios({
                url: "/admin/asset-fixed/add-fixed",
                method: "POST",
                data: form,
              }).then((res) => {
                if (res.error == 0) {
                  this.$message.success("添加成功！");
                  this.form.list = null; // 清空固定资产记录列表
                } else {
                  this.$message.error(res.msg);
                }
              });
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.info-table {
  margin-top: 12px;
}
</style>
